
<template>
	<div :class="classes">
        <h3 class="first">
            {{$t(header)}}
            <ExtraInfo v-if="edit" :id="header"></ExtraInfo>
        </h3>
		<span class="courseedit-sis-table data-clean">
			<div class="data-clean" v-html="translateTable(text)"></div>
			<br>
		</span>
	</div>
</template>

<script>
import Item from "../../mixins/Item";
import ExtraInfo from "./ExtraInfo";
import { table_translations } from "../../assets/translations.js";

export default {
    name: "table-item",
    components: {
        ExtraInfo
    },
	props: {},
	mixins: [Item],
	data: function() {
		return {
			text: "",
			translations: table_translations
		};
	},
	computed: {
		classes(){
			return this.edit ? `${this.$i18n.locale} ${this.extraClass} readonly` : ""
		}
	},
	created() {
		if(!this.value || this.value === ""){
			this.text = `<i>${this.$t('general.noValue')}</i>`
		} else {
			this.text = this.value;
		}
	},
	methods: {
		onSave() {},
		toOriginal() {},
		translateTable(text) {
			if (this.$i18n.locale == "en" && text) {
                text = text.replace(/[\u00AD\u002D\u2011]/gi, "");
                if (this.translations) {
                    this.translations.forEach(function (translationPair) {
						text = text.replace(translationPair[0], translationPair[1]);
                    });
                }
			}
			return text;
		},
	},
};
</script>
<style lang="less" scoped>
@import "../../assets/item.less";

.readonly {
	.snippet("");
	&::before {
		content: "Read-only";
	}

	&.sis {
		.nl &::before {
			content: "Geimporteerd uit SIS";
		}
		.en &::before {
			content: "Imported from SIS";
		}
	}
}
</style>
