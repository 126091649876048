<template>
	<div class="bootstrap-hva">
		<div class="container gutter-hva fix-mobile-width">
			<editorBar v-if="programBaseitem && programBaseitem.CourseCatalogItem && userCanEditCourses" v-model="programBaseitem.CourseCatalogItem.EditorialState" :isEditing="isEditing" :isSaving="isSaving" :isDirty="isDirty" :saveMessage="saveMessage" :showCourseCatalogWarning="isExchange && programBaseitem.ShowInCourseCatalogOrShowInCourseCatalogEdit" @switchFuture="onSwitchFuture" @switchView="onSwitchView" @save="saveBaseItem"/>
			<overview-header :baseitem="programBaseitem" :isEditing="isEditing" :futureEditMode="futureEditMode" />
			<multipleWarning v-if="isEditing" :baseItemId="programBaseitem.Id" />
			<div class="row">
				<div class="col-md-3">
					<side-menu class="vue-menu" :userCanEditProgram="userCanEditPhasing && isEditing" />
				</div>
				<div class="col col-md-9 overview-content course-content" id="contents">
					<div v-if="!loading && !loadingItem">
						<div v-for="(item, index) in items" :key="updateNumber + index">
							<template v-if="isEditing || (item.value !== undefined && item.value.length > 0)">
								<text-item v-if="item.type == 'text'" v-bind="item" set :edit="isEditing && (futureEditMode || originalEditorialState !== 'Gereed')" @change="updateSave" @blur="onBlur" @dirty="onDirty" @requiredFieldNotFilled="requiredFieldNotFilled" @requiredFieldFilled="requiredFieldFilled" />
								<link-item v-if="item.type == 'link'" v-bind="item" set :edit="isEditing && (futureEditMode || originalEditorialState !== 'Gereed')" @change="updateSave" @blur="onBlur" @dirty="onDirty" />
								<readonly v-if="item.type == 'readonly'" v-bind="item" set :edit="isEditing" />
								<table-item v-if="item.type == 'table'" v-bind="item" set :edit="isEditing" />
								<array-item v-if="item.type == 'array'" v-bind="item" set :edit="isEditing" />
								<people-item v-if="item.type == 'people'" v-bind="item" set :edit="isEditing && (futureEditMode || originalEditorialState !== 'Gereed')" @change="updateSave"  @dirty="onDirty" @requiredFieldNotFilled="requiredFieldNotFilled" @requiredFieldFilled="requiredFieldFilled" />
							</template>
						</div>
					</div>
					<spinner v-else />
                    <router-link v-if="!loading" target="_blank" :to="{ name: 'print-course', params: { programSlug: this.$route.params.programSlug, acYear: acYear, baseItemId: this.$route.params.baseItemId}}">
                        <div class="pdfDownload download-link">{{$t("general.download")}}</div>
                        <font-awesome-icon icon="file-pdf" class="fontAwesome" />
                        <font-awesome-icon icon="print" class="fontAwesome" />
                    </router-link>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    /*eslint-disable */

import TableItem from "../components/EditPages/TableItem.vue"
import ArrayItem from "../components/EditPages/ReadonlyArray.vue"
import PeopleItem from "../components/EditPages/PeopleItem.vue"
import Overview from "../mixins/Overview.js";
import multipleWarning from "../components/EditPages/MultipleWarning.vue";
import { mapState } from "vuex";
import { put } from 'common/store/storeFunctions.js'

export default {
	components: {
        TableItem,
        ArrayItem,
		PeopleItem,
		multipleWarning
	},
	mixins: [Overview],
	data() {
		return {
			updateNumber: 0, //Property needed for reactivity of items
			futureEditMode: false,
			courseCatalogId: null,
			loadingItem: false
		};
	},
    computed: {
        acYear() {
            var acYear = this.$route.params.acYear;
            if (acYear == null && this.acYears) {
                acYear = this.acYears.currentAcademicYear;
            }
            return acYear;
        },
        programDetails() {
           if (this.acYear) {
                var params = this.$route.params;
                var key = this.acYear + params.programSlug;
                return this.program[key] ? this.program[key].details : null;
            } else {
                return null;
            }
		},
        programBaseitem() {
			if (this.acYear) {
                var params = this.$route.params;
				var key = this.acYear + params.programSlug;
                var baseitemid = params.baseItemId;
				if (this.futureEditMode) {
                    return this.program[key] && this.program[key].coursesFutureEdit && this.program[key].coursesFutureEdit[baseitemid] ? this.program[key].coursesFutureEdit[baseitemid] : JSON.parse(JSON.stringify(this.program[key].courses[baseitemid]));
				} else {
					return this.program[key] && this.program[key].courses ? this.program[key].courses[baseitemid] : null;
				}
            } else {
                return null;
            }
		},
		loading() {
			return this.programBaseitem == null;
		},
         userCanEditCourses() {
            var facultyId = this.programBaseitem.FacultyId;
			var facultyBaseItemEditor = false;
			if (this.isExchange && ((this.roles.ExchangeProgramCourseEditor && this.roles.ExchangeProgramCourseEditor.indexOf(facultyId) != -1) || (this.roles.ExchangeProgramCourseFinalEditor && this.roles.ExchangeProgramCourseFinalEditor.indexOf(facultyId) != -1))){
				facultyBaseItemEditor = true;	
			}
			if (!this.isExchange && ((this.roles.CourseCatalogCourseEditor && this.roles.CourseCatalogCourseEditor.indexOf(facultyId) != -1) || (this.roles.CourseCatalogCourseFinalEditor && this.roles.CourseCatalogCourseFinalEditor.indexOf(facultyId) != -1))){
				facultyBaseItemEditor = true;				
			}
            return facultyBaseItemEditor && this.programBaseitem.Editable && (this.userIsCourseEditor || this.userIsCourseFinalEditor);
            
        },
        userCanEditPhasing() {
            if (this.programDetails) {
                var facultyId = this.programDetails.FacultyId;
                var facultyProgramEditor = false;
                if (this.isExchange && ((this.roles.ExchangeProgramProgramEditor && this.roles.ExchangeProgramProgramEditor.indexOf(facultyId) != -1) || (this.roles.ExchangeProgramProgramFinalEditor && this.roles.ExchangeProgramProgramFinalEditor.indexOf(facultyId) != -1))) {
                    facultyProgramEditor = true;
                }
                if (!this.isExchange && ((this.roles.CourseCatalogProgramEditor && this.roles.CourseCatalogProgramEditor.indexOf(facultyId) != -1) || (this.roles.CourseCatalogProgramFinalEditor && this.roles.CourseCatalogProgramFinalEditor.indexOf(facultyId) != -1))) {
                    facultyProgramEditor = true;
                }
                return facultyProgramEditor && this.programDetails.Editable && (this.userIsProgramEditor || this.userIsProgramFinalEditor);
            } else {
                return false;
            }
		},
		...mapState(["program", "acYears"])
	},
	watch:{
		programBaseitem: function(){
			this.populateData();
		}
	},
	created(){
		this.populateData();
	},
		methods: {
			async onSwitchFuture() {
				if (!this.futureEditMode) {
					var model = this;
					model.courseCatalogId = model.programBaseitem.CourseCatalogItem.Id;
                    model.loadingItem = true;
                    var params = model.$route.params;
					params.futureEditCourseCatalogId = model.courseCatalogId;
					params.baseItem = model.programBaseitem;
                    await model.$store.dispatch("getFutureEdit", params);
                    model.loadingItem = false;
				}
                this.futureEditMode = !this.futureEditMode;
            },
            onBlur() {
				var model = this;
				if (model.isDirty && !model.futureEditMode) {
                    model.autoSave();
                }
            },
			updateSave(item, value) {
                this.items[item].set(value);
				var model = this;
                if (model.isDirty && !model.futureEditMode) {
					if (model.autoSaveTimerId == null) {
                        model.autoSaveTimerId = setInterval(model.autoSave, 300000);
					}
				}
            },
            autoSave() {
				var model = this;
                put(`/api/coursecatalog/savecopy/false`, model.programBaseitem.CourseCatalogItem);
            },
		populateData(){
			var model = this;
			if(this.programBaseitem === undefined || this.programBaseitem === null) return;
            this.isEditing = this.userCanEditCourses;
			this.originalEditorialState = this.programBaseitem.CourseCatalogItem.EditorialState;
            var pb = this.programBaseitem;
			var lang = null;
			if (this.programDetails.ShowInEnglish) { lang = 'en_GB' }
            var signUpRulesStartYear = pb.AcademicYear.substring(0,4);
            var showSignUpRules = parseInt(signUpRulesStartYear) >= 2022;
			this.items = {
				"commonLabels.academicYear": model.createReadOnly(pb, "commonLabels.academicYear", "CourseCatalogItem.AcademicYear", true, false, "sis"),
				"commonLabels.catalogueNumber": model.createReadOnly(pb, "commonLabels.catalogueNumber", "CourseCatalogItem.CatalogueNumber", true, false, "sis"),
				"editPageLabels.descriptionSIS": model.createReadOnly(pb, "editPageLabels.descriptionSIS", "CourseCatalogItem.SisDataItem.DescriptionSis", true, false, "sis"),
				"programOverview.programmeDetails.credits": model.createReadOnly(pb, "programOverview.programmeDetails.credits", "CourseCatalogItem.SisDataItem.Credits", true, false, "sis"),
				"commonLabels.programme": model.createReadOnly(pb, "commonLabels.programme", "Program", true),
				"commonLabels.period": model.createReadOnlyArray(pb, "commonLabels.period", "Groupings", false),
				"commonLabels.description": model.createText(pb, "commonLabels.description", "CourseCatalogItem.Description", true, lang, 1500),
				"editPageLabels.timeAndPlace": model.createText(pb, "editPageLabels.timeAndPlace", "CourseCatalogItem.ScheduleAndLocation", false, lang, 1500),
				"commonLabels.language": model.createText(pb, "commonLabels.language", "CourseCatalogItem.Language", true, lang, 100),
				"commonLabels.presence": model.createText(pb, "commonLabels.presence", "CourseCatalogItem.Attendance", false, lang, 1500),
                "commonLabels.entryCriteria": model.createText(pb, "commonLabels.entryCriteria", "CourseCatalogItem.EntryRequirements", false, lang, 800),
                ...(showSignUpRules && {"commonLabels.signUpRules": model.createReadOnly(pb, "commonLabels.signUpRules", "CourseCatalogItem.SignUpRules", true, false)}),
				"editPageLabels.teachingMethods": model.createTable(pb, "editPageLabels.teachingMethods", "CourseCatalogItem.SisDataItem.TeachingMethods", false),
				"commonLabels.teachingMethodsRemarks": model.createText(pb, "commonLabels.teachingMethodsRemarks", "CourseCatalogItem.TeachingMethodsDescription", false, lang, 800),
				"editPageLabels.testType": model.createTable(pb, "editPageLabels.testType", "CourseCatalogItem.SisDataItem.AssesmentMethods", true),
				"editPageLabels.testTypeRemarks": model.createText(pb, "editPageLabels.testTypeRemarks", "CourseCatalogItem.AssesmentMethodsDescription", true, lang, 800),
				"commonLabels.contactTime": model.createText(pb, "commonLabels.contactTime", "CourseCatalogItem.Contacttime", false, lang, 800),
				"editPageLabels.teachers": model.createPeople(pb, "editPageLabels.teachers", "CourseCatalogItem.Lecturers", false),
				"commonLabels.coordinators": model.createPeople(pb, "commonLabels.coordinators", "CourseCatalogItem.Coordinators", true),
				"commonLabels.studyMaterial": model.createText(pb, "commonLabels.studyMaterial", "CourseCatalogItem.Coursematerials", true, lang, 800),
				"commonLabels.competencies": model.createText(pb, "commonLabels.competencies", "CourseCatalogItem.LearningObjectives", true, lang, 1500),
                "commonLabels.practice": model.createText(pb, "commonLabels.practice", "CourseCatalogItem.ProfessionalContext", false, lang, 1500),
                "commonLabels.portfolioProducts": model.createText(pb, "commonLabels.portfolioProducts", "CourseCatalogItem.PortfolioProducts", false, lang, 800),
				"commonLabels.courseLinks": model.createText(pb, "commonLabels.courseLinks", "CourseCatalogItem.CourseLinks", false, lang, 800),
				"commonLabels.learningTrack": model.createText(pb, "commonLabels.learningTrack", "CourseCatalogItem.LearningTrack", false, lang, 300),
				"commonLabels.courseId": model.createReadOnly(pb, "commonLabels.courseId", "CourseCatalogItem.CourseId", false, true, "sis"),
				"editPageLabels.discipline": model.createReadOnly(pb, "editPageLabels.discipline", "CourseCatalogItem.Discipline", false, true, "sis"),
				"editPageLabels.disciplineRemarks": model.createReadOnly(pb, "editPageLabels.disciplineRemarks", "CourseCatalogItem.SisDataItem.DisciplineDescription", false, true, "sis"),
				"editPageLabels.organization": model.createReadOnly(pb, "editPageLabels.organization", "CourseCatalogItem.SisDataItem.Organisation", true, true, "sis")
			};
			this.updateNumber++;
		},
		saveBaseItem: async function() {
			var model = this;
			model.isSaving = true;
			if (model.programBaseitem.CourseCatalogItem.EditorialState === "Aangemaakt" || model.programBaseitem.CourseCatalogItem.EditorialState === "Invoeren" && model.saveMessage.requiredFieldsNotFilled.length > 0) {
				var success = await model.updateBaseItemToCrm();
				if(success){
					model.saveMessage.type = "warning";
				}
			} else if ((model.programBaseitem.CourseCatalogItem.EditorialState === "Goedkeuren" || model.programBaseitem.CourseCatalogItem.EditorialState === "Gereed") && model.saveMessage.requiredFieldsNotFilled.length > 0) {
				model.saveMessage.type = "error";
			} else {
				var success = await model.updateBaseItemToCrm();
				if(success){
					model.saveMessage.type = "";
				}
			}
			model.isSaving = false;
		},
		async updateBaseItemToCrm(){
			var model = this;
			model.isDirty = false;
            var acYear = this.acYears.currentAcademicYear;
            var params = this.$route.params;
            if (params.acYear) {
                acYear = params.acYear;
            }
            var key = acYear + params.programSlug;
			var updateKey = `program.${key}.courses.${params.baseItemId}`;
			if (model.futureEditMode) {
				updateKey = `program.${key}.coursesFutureEdit.${params.baseItemId}`;
				params.baseItem = this.program[key].courses[params.baseItemId];
				try {
					var futureItemAlreadyExists = model.courseCatalogId != model.programBaseitem.CourseCatalogItem.Id;
					if (futureItemAlreadyExists) {
						await put(
							`/api/coursecatalog/updateFutureEdit`,
							model.programBaseitem.CourseCatalogItem
						);
					} else {
                        await put(
                            `/api/coursecatalog/savecopy/true`,
                            model.programBaseitem.CourseCatalogItem
                        );
					}
                } catch (errorMessage) {
                    console.log(errorMessage);
                    this.$store.commit("resetCacheForKey", updateKey);
                    this.$store.dispatch("getFutureEdit", params);
                    model.saveMessage.type = "updateError";
                    return false;
                }
                this.$store.commit("resetCacheForKey", updateKey);
                await this.$store.dispatch("getFutureEdit", params);
                window.onbeforeunload = null;
                return true;
			} else {
				try {
					await put(
						`/api/coursecatalog/update`,
						model.programBaseitem.CourseCatalogItem
					);
				} catch (errorMessage) {
					console.log(errorMessage);
					this.$store.commit("resetCacheForKey", updateKey);
					this.$store.dispatch("getCourse", params);
					model.saveMessage.type = "updateError";
					return false;
				}

				this.originalEditorialState = this.programBaseitem.CourseCatalogItem.EditorialState;
				this.$store.commit("resetCacheForKey", updateKey);
				await this.$store.dispatch("getCourse", params);
				window.onbeforeunload = null;
				return true;
			}
			}
		},
		beforeRouteLeave(to, from, next) {
            var model = this;
			if (to.name === "clone") {
				this.$store.commit("setProgramBaseItemToBeClonedTo", this.programBaseitem);
			}
			if (to.name === "autosaves") {
                var autoSavesObject = {};
				autoSavesObject.Item = this.programBaseitem.CourseCatalogItem;
                autoSavesObject.Id = this.programBaseitem.CourseCatalogItem.Id;
                autoSavesObject.Type = "courseCatalog";
                this.$store.commit("setAutoSaveIdTo", autoSavesObject);
            }
            model.clearAutoSave(model);
		next();
	},
        beforeRouteEnter(to, from, next) {
        next(vm => {
            var toParams = to.params;
            if (from.name === "clone" || from.name == "autosaves") {
                var acYear = vm.acYears.currentAcademicYear;
                if (toParams.acYear) {
                    acYear = toParams.acYear;
                }
                var key = acYear + toParams.programSlug;
				var updateKey = `program.${key}.courses.${to.params.baseItemId}`;
				vm.$store.commit("resetCacheForKey", updateKey);
			}
            vm.$store.dispatch("getCourse", toParams);
		});
	}
};
</script>
<style lang="less">
@import "../assets/overview.less";
button.helpButton {
	margin-top: -44px;
	margin-right: 10px;
}

</style>
<style>
div.course-content div.data-group div.block-grid span:after {
	content: ", ";
}

div.course-content div.data-group div.block-grid span:last-child:after {
	content: "";
}
.course-header h2 {
	text-transform: uppercase;
	margin-top: 36px !important;
}


#app .course-content table tbody td, #app .course-content table thead th {
    border: 1px solid #e6e6e6;
    line-height: 22.5px;
    padding: 5px 10px;
    vertical-align: top;
}

#app .course-content h3 {
	font-weight: bold;
	text-transform: none;
}
</style>