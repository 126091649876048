<template>
	<div :class="classes">
        <h3 class="first">
            {{$t(header)}}
            <ExtraInfo v-if="edit" :id="header"></ExtraInfo>
        </h3>
		<div class="data-content data-clean text-content block-grid">
			<span v-for="(item, index) in valueArray" :key="item">{{item + (valueArray[index +1] ? ", " : "")}}</span>
		</div>
	</div>
</template>

<script>
    import Item from "../../mixins/Item";
    import ExtraInfo from "./ExtraInfo";

export default {
    name: "readonlyarray-item",
    components: {
        ExtraInfo
    },
	props: {},
	mixins: [Item],
	data: function() {
		return {
			valueArray: ""
		};
	},
	methods: {
		onSave() {},
		toOriginal() {}
	},
	computed: {
		classes(){
			return this.edit ? `${this.$i18n.locale} ${this.extraClass} readonly` : ""
		}
	},
	created() {
		if(!this.value || this.value.length === 0){
			this.valueArray = [];
			this.valueArray.push(`<i>${this.$t('general.noValue')}</i>`)
		} else {
			this.valueArray = this.value;
		}
	}
};
</script>
<style lang="less" scoped>
@import "../../assets/item.less";

.readonly {
	.snippet("");
	&::before {
		content: "Read-only";
	}

	&.sis {
		&.nl::before {
			content: "Geïmporteerd uit SIS";
		}
		&.en::before {
			content: "Imported from SIS";
		}
	}
}
</style>
