<template>
	<div class="people" v-if="edit || selection.length > 0">
		<div v-if="edit">
            <h3>
                {{$tc(header, selection.length)}} <span class="required" v-show="required && edit">*</span>
                <ExtraInfo :id="header" v-if="edit"></ExtraInfo>
            </h3>
			<multiselect v-bind="props" label="Name" track-by="Id" :options="results" v-model="selection" :loading="searching" @input="onDirty" @search-change="onSearch" @close="onBlur">
				<span slot="noOptions">{{$t('peopleset.nooptions')}}</span>
				<span slot="noResult">{{$t('peopleset.noresults')}}</span>
			</multiselect>
		</div>
		<div v-else>
			<h3>{{$tc(header, selection.length)}}</h3>
			<div class="view" v-html="readOnly"></div>
		</div>
	</div>
</template>

<script>
/*eslint-disable */
import Item from "../../mixins/Item";
import Multiselect from "vue-multiselect";
    import { get } from "common/store/storeFunctions";
    import ExtraInfo from "./ExtraInfo";

export default {
	name: "people-item",
	components: {
		Multiselect, ExtraInfo
	},
	mixins: [Item],
	data: function() {
		return {
			searching: false,
			debounce: 0,
			selection: [],
			results: []
		};
    },
  	props: {
        required: Boolean
    },
	watch: {
        selection(n, o) {
			this.$emit("change", this.header, n);
            this.checkRequiredness();
            this.results = [];
		}
	},
	computed: {
		readOnly() {
			var read = [];
			this.selection.forEach(item => {
				read.push(`<span>${item.Name}</span>`);
			});
			return read.join(", ");
		}
	},
	created() {
		this.props = {
			closeOnSelect: false,
			multiple: true,
			openDirection: "below",
			selectLabel: this.$t("optionset.select"),
			selectedLabel: this.$t("optionset.selected"),
			deselectLabel: this.$t("optionset.deselect"),
			placeholder: this.$t("peopleset.placeholder"),
			searchable: true,
            internalSearch: false
		};

		this.selection = this.value;
		this.revert = this.selection;
		this.checkRequiredness();
	},
	methods: {
		onSearch(search) {
			var model = this;
			if (search.length >= 3) {
				var d = ++model.debounce;
				return new Promise((resolve, reject) => {
					setTimeout(function() {
						if (model.debounce == d) resolve();
					}, 1000);
				}).then(async function() {
					model.searching = true;
					model.results = await get(`${location.protocol}//${location.host}/api/userinfo/getbyquery/${search}`);
					model.searching = false;
					model.debounce = 0;
				});
			}
		},
		onSave() {
			this.revert = this.selection;
		},
		toOriginal() {
			this.selection = this.revert;
		},
		checkRequiredness(){
			if(this.required){
				if(this.selection.length === 0){
					this.$emit("requiredFieldNotFilled", this.header);
				} else {
					this.$emit("requiredFieldFilled", this.header);
				}
			}
		},
		onBlur() {
			this.$emit("blur");
		}
	},
};
</script>
<style lang="less" scoped>
@import "../../assets/item.less";
@import "../../assets/multiselect.less";
</style>
