<template>
    <div v-if="programmes.length > 1" class='multipleWarning'>
        <span><span class="warningLabel">{{$t("courseWarning.warningLabel")}}:</span> {{$t("courseWarning.warningText")}}:</span>
        <ul class="warning-program-list" v-for="program in programmes" :key="program.index">
            <li><router-link :to="{ name: 'program-overview', params: { programSlug: program.Slug, acYear: $route.params.acYear ? $route.params.acYear : '' } }">{{ program.Text }} </router-link></li>
        </ul>
    </div>
</template>

<script>
    import { get } from 'common/store/storeFunctions.js'

    export default {
        name: "multipleWarning",
        props:["baseItemId"],
        data: function () {
            return {
                programmes: []
            };
        },
        async created() {
            var model = this;
            var warningUrl = `/api/coursecatalog/getwarningdata/${model.baseItemId}/${model.language}/`;
            var warning = await get(warningUrl);

            for (var i in warning) {
                //sanity checks
                if (warning[i].BaseItem != null && warning[i].Program != null && warning[i].Phasings != null && warning[i].Groupings != null) {
                    model.programmes.push({
                        Slug: warning[i].ProgramSlug,
                        Text: warning[i].Program + " - " + warning[i].Phasings.join(", ") + " - " + warning[i].Groupings.join(", ")
                    });
                }
            }
        }
    };
</script>
<style lang="less" scoped>
#app{
    span, ul, li{
        font-size: 15px;
    }
    .warning-program-list {
        margin-top: 15px;
    }
}

div.multipleWarning span, div.multipleWarning ul li {
  font-size: 15px;
}
</style>